@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: poppins;
}
.sidebar-layout{
    z-index:3;
    position: absolute;
    left: 0;
    top: 0;
}
.sidebar{
    width: 11vw;
    height: 11vw;
    /* background: linear-gradient(180deg, #C5F5E9 0%, #DBF4EE 100%); */
    /* background: radial-gradient(338.08% 110.77% at 0% 0%, #8CF1D9 0%, rgba(238, 145, 238, 0.43) 100%); */
    background: radial-gradient(644.59% 166.30% at -0.00% 0%, #78E9CE 0%, rgba(233, 118, 233, 0.76) 100%);

    box-shadow: 1px 0px 40px 16px rgba(8, 104, 80, 0.12);
    transition: .8s all;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: sidebar;
    animation-duration: 1.5s;
    animation-delay: 0s;
    animation-iteration-count: 1;
}
@keyframes sidebar {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(0);
    }
}
.sidebar.sidebar-active{
    height: 100vh;
    background: radial-gradient(446.59% 120.03% at 0% 0.00%, #78E9CE 0%, rgba(233, 118, 233, 0.76) 100%);
}
.menu-link{
    width: 11vw;
    height: 11vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}
.menu-link button {
    background: transparent;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: .12vw solid black;
    cursor: pointer;
    font-size: 1.17vw;
    color: black;
}
.sidebar ul{
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    gap:7.5vw;
    display: flex;
    flex-direction: column;
    transition: .2s all;
    transition-delay: 0;
    z-index: 2;
}
.sidebar-active ul{
    transition: .5s all;
    opacity: 1;
    transition-delay: .3s;
}
.sidebar ul li{
    transform: rotate(-90deg);
    text-transform: uppercase;
    font-size: 1.3vw;
    color: black;
    cursor: pointer;
}
.sidebar ul li a{
    color: black;
}
@media only screen and (min-width: 1280px){
    .sidebar{
        width: 12.5vw;
        height: 12.5vw;
    }
    .menu-link{
        width: 12.5vw;
        height: 12.5vw;
    }
    .menu-link button {
        font-size: 1.7vw;
    }
    .sidebar ul{
        gap:8vw;
    }
    .sidebar ul li{
        font-size: 1.6vw;
    }
}

.home{
    width: 100%;
    height: 100vh;
    background-image: url(../images/new-main-banner.jpg);
    background-position: 10% 10%;
    background-size: cover;
    animation-name: header;
    animation-duration: 2s;
    animation-delay: 0s;
    position: relative;
    animation-iteration-count: 1;
    overflow: hidden;
}
@keyframes header {
    0%{
        background-size: 130%;
    }
    100%{
        background-size: 100%;
    }
}
.logo{
    position: absolute;
    top: 0;
    max-width: 30%;
    left: 50%;
    transform: translateX(-50%);
    animation-name: logo;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    z-index:4;
    display: block;
}
  .logo.active {
    z-index: 1; /* Applied when the popup is active */
  }
.hide-logo{
    display: none;
}
@keyframes logo {
    0%{

        transform: translateY(-20%) translateX(-50%);
    }
    100%{
        transform: translateY(0) translateX(-50%);
    }
}
.home-content{
    position: absolute;
    left: 19%;
    top: 50%;
    transform: translateY(-50%);
    color: black;
}
.home-content img{
    max-width: 54%;
    margin-bottom: -.3vw;
    animation-name: heading;
    animation-duration: 1.1s;
    animation-delay: 0s;
    animation-iteration-count: 1;
}
@keyframes heading {
    0%{
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform:scale(1);
    }
}
.home-content h1{
    text-transform: uppercase;
    font-size: 4.6vw;
    font-weight: 700;
    letter-spacing: .22vw;
    margin-left: 8vw;
    animation-name: heading-nd;
    animation-duration: 1.1s;
    animation-delay: 0;
    animation-iteration-count: 1;
}
@keyframes heading-nd {
    0%{
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.home-content button{
    background: transparent;
    border: .12vw solid black;
    padding: .8vw 2vw;
    font-size: 1.1vw;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 8.2vw;
    margin-top: .6vw;
    cursor: pointer;
    animation-name: btn;
    animation-duration: 1.1s;
    animation-delay: 0;
    z-index: 1;
    animation-iteration-count: 1;
}
@keyframes btn {
    0%{
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}

@media only screen and (min-width: 1280px){
    .logo{
        max-width: 36%;
    }
    .home{
        background-position: 10% 10%;
        background-size: 100%;
        background-repeat: no-repeat;
        /* animation-name: inherit; */
    }
    .home-content img{
        max-width: 60%;
        margin-bottom: -.3vw;
    }
    .home-content h1{
        font-size: 5.7vw;
        margin-left: 9vw;
    }
    .home-content button{
        border: .14vw solid black;
        padding: .8vw 2vw;
        font-size: 1.5vw;
        margin-left: 9.5vw;
        margin-top: .6vw;
    }
    @keyframes header {
        0%{
            background-size: 130%;
        }
        100%{
            background-size: 100%;
        }
    }
}
@media only screen and (max-width:768px) {
    .home{
        background-size: 370%;
        background-position: right;
        background-repeat: no-repeat;
    }
    @keyframes header {
        0%{
        background-size:400%;
        }
        100%{
            background-size:370%;
        }
    }
    .sidebar{
        z-index: 5;
        width: 22vw;
        height: 22vw;
        color: black;
    }
    .menu-link{
        width: 22vw;
        height: 22vw;
    }
    .menu-link button{
        font-size: 3.6vw;
        color: black;
    }
    .sidebar ul{
        gap: 27vw;
    }
    .sidebar ul li{
        font-size: 4.3vw;
        font-weight: 500;
    }
    
    .logo{
        top: 0;
        max-width: 100%;
        z-index: 1;
        display: none;
    }
    .home-content{
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
    }
    .home-content img{
        max-width: 100%;
        margin-bottom: 1vw;
    }
    .home-content h1{
        font-size: 8.5vw;
        letter-spacing: .22vw;
        margin-left:inherit;
        text-align: center;
    }
    .home-content button{
        border: .12vw solid black;
        padding: 1.9vw 5vw;
        font-size: 4.5vw;
        margin-left: inherit;
        margin: auto;
        margin-top: 5vw;
        display: block;
    }
}


.gallery{
    width: 100%;
    height: 100vh;
    background-image: url(../images/new-main-gallery.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}
.swiper{
    z-index: 1 !important;
    display: block !important;
}
@media (max-width:768px) {
    .swiper{
        display: none !important;
    }
}
.swiper-slide{
    height: 100vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding-top: 6vw !important;
    -webkit-transform: translateZ(0) !important;
-webkit-backface-visibility: hidden !important;
}
.box-gallery{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.7vw;
}
.box-gallery h1{
    font-size: 2.2vw;
    font-weight: 600;
    margin-top: -.8vw;
}
.image-gallery{
    max-width: 38.5vw;
    max-height: 31vw;
    border: 1vw solid white !important;  object-fit: contain;
    object-position: center;
    box-shadow: 3px 3px 7px 0px rgba(0,0,0,0.43) !important;
    -webkit-box-shadow: 3px 3px 7px 0px rgba(0,0,0,0.43) !important;
    -moz-box-shadow: 3px 3px 7px 0px rgba(0,0,0,0.43) !important; 
    opacity: 1;
    transition: .5s all;
    transform: scale(0);
}
.swiper-slide-active .image-gallery{
    transform: scale(1);
}
.heading-gallery{
    max-width: 8vw;
    margin-top: -1vw;
}
.info-button{
    position: absolute;
    top: 40vh;
    right: 25vw;
    background: white;
    border: none;
    width: 14vh;
    height: 14vh;
    border-radius: 9vw;
    cursor: pointer;
    font-size: 3vh;
    text-transform: uppercase;
    box-shadow: 0px 4px 22px rgba(0, 95, 71, 0.17); 
    display: none;
    z-index: 10; 
}
.swiper-slide-active .info-button{
    animation-duration: 1s !important;
    animation-delay: 0s !important;
    animation-iteration-count: 1 !important;
    animation-name: info !important;
    display: block !important;
}
.swiper-slide-active .glow-effect{
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: info;
    display: block;
    position: absolute;
    top: 40vh;
    right: 25vw;
    width: 14vh;
    height: 14vh;
}
@keyframes info {
    0% {
      transform: scale(0) ;
    }
    80%{
        transform: scale(0) rotate(360deg);
    }
    100% {
      transform: scale(1) rotate(0);
    }
}
.glow{
    position: absolute;
    border-radius: 9vw;
    width: 14vh;
    height: 14vh;
    border: 4px solid rgb(255, 255, 255);
    animation: glow 1.8s  infinite ;
}
.glow2{
    animation: glow2 1.6s  infinite ;
}
.glow3{
    animation: glow3 1.6s  infinite ;
}
.glow4{
    animation: glow4 1.6s  infinite ;
}
@keyframes glow4 {
    0%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(1.2);
        opacity: 0;
    }
}
@keyframes glow3 {
    0%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(1.3);
        opacity: 0;
    }
}
@keyframes glow2 {
    0%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(1.4);
        opacity: 0;
    }
}
@keyframes glow {
    0%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(1.5);
        opacity: 0;
    }
}
.popup{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: -1;
    top: 0;
    backdrop-filter: blur(4px) !important;
    background: rgba(255, 255, 255, 0.384);
    opacity: 0;
    transition: .4s all;
}
.popup.active{
    opacity: 1;
    z-index: 30;
}
.popup-content{
    background: white;
    width: 100%;
    height: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: .4s all;
    opacity: 0;
}
.popup.active .popup-content{
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.close-button{
    position: absolute;
    right: 5%;
    top: -10%;
    background: transparent;
    border: none;
    cursor: pointer;
}
.close-button img{
    max-width: 70%;
}
.popup-card-art{
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: max-content;
    background-color: white;
    animation-name: popup;
    animation-duration: 10s all;
    animation-delay: 0s;
    animation-iteration-count: 1;
    transform: scale(0) translateY(-50%);
    opacity: 0;
    transition: 0.4s all;
    z-index: 99;
}
.popup-art{
    display: grid;
    grid-template-columns: 49% 31% 20%;
    width: 100%;
    padding: 0;
    justify-content: center;
}

.show-art{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: block;
    width: 100%;
    margin: auto;
    height: 100vh;
    transition: all 0.5s;
    z-index: 12;
    opacity: 1;
    background-image: url(../images/bg-pop-up.png);
    background-position: top;
    background-size: cover;
}
.show-art .popup-card-art{
    opacity: 1;
    transform: scale(1) translateY(-50%);
}
.hide-art{
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2 !important;
    height:100vh;
    opacity:0;
    transition: all 0.5s;
    /* background-image: url(../public/image/background-about.jpg); */
    background-position: top;
    background-size: cover;
}
.image-box{
    text-align: end;
    width:100%;
}
.image-box-art{
    width: 90%;
    height: 66.6vh;
    position: relative;
    margin: auto;
    margin-right: 6.5vw;
}
.image-box-art img{
    max-width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: right center;
    top:0;
    right: 0;
}
.logo-box-image img{
    width: 9vw;
}
.box-content{
    padding-top: 3.5vw;
    margin-left: 0;
    padding-right: 5vh;
    text-align: start;
}
.box-content h1{
    text-align: start;
    text-transform: uppercase;
    font-size: 7vh !important;
    line-height: 1.1;
    letter-spacing: 2px;
}
.box-content p{
    margin-top: 0.7vw;
    font-size: 2.3vh;
    margin-bottom: 1vw;
    padding-right: 2vw;
    font-weight: 500;
}
.box-content span{
    font-size: 1.8vh;
    font-weight: 300;
}
.logo-box{
    display: flex;
    align-items: center;
    height: max-content;
    gap: 1vw;
    margin-top: 5vw;
    margin-left: 2vw;
}
.logo-box img{
    width:6vw;
}
.logo-box p{
    font-size: 1.8vw;
}
.button-popup{
    position: absolute;
    top: 9%;
    right: 6.3%;
    border: none;
    background: transparent;
    font-size: 2vw;
    cursor: pointer;
}
.button-popup img{
    max-width: 2.3vw;
}
.swiper-button-prev {
    background-image: url(../images/prev.png) !important;
    background-repeat: no-repeat;
    background-size: 100%;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count:1;
    animation-name:swiper-prev;
    z-index: 1 !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev{
    right: 14vh !important;
    left: auto;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    content: none !important;
}
.swiper-button-next {
    background-image: url(../images/next.png) !important;
    background-repeat: no-repeat;
    background-size: 100%;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count:1;
    animation-name:swiper-next;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
    left: 14vh !important;
    right: auto;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    content: none !important;
}
.swiper-button-next, .swiper-button-prev{
    position: absolute;
    top: 50%;
    width: 8vh !important;
    height: 3vh !important;
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 1 !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
}
@keyframes swiper-prev {
    0%{
        transform: translateX(-170%);
    }
    100%{
        transform: translateX(0);
    }
}
@keyframes swiper-next {
    0%{
        transform: translateX(170%);
    }
    100%{
        transform: translateX(0);
    }
}
@media (max-width:768px) {
    .gallery{
        background-position: right top;
        overflow-x:hidden;
    }
    .image-gallery{
        max-width: 85.5vw;
        max-height: 83vw;
        border: 1.8vw solid white !important;
        transform: scale(0);
    }
    .box-gallery h1{
        font-size: 7.5vw;
        margin-top: 1.8vw;
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev{
        right: 24vw !important;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next{
        left: 24vw !important;
    }
    .swiper-button-next, .swiper-button-prev{
        top: 88% !important;
        width: 8vh !important;
        height: 3vh !important;
        margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    }
    .heading-gallery{
        max-width: 28vw;
        margin-top: 0;
    }
    .info-button{
        top: 40vh;
        right: 3vw;
        border: none;
        width: 17vw;
        height: 17vw;
        border-radius: 14vw;
        font-size: 4.4vw;
    }
    .popup-content{
        padding: 4vw 4vw;
    }
    .popup-art{
        grid-template-columns:100%;
        padding: 0;
        justify-content: center;
    }
    .image-box-art{
        width: 100%;
        height: 73.4vw;
        margin-right: inherit;
        object-position: center;
    }
    .image-box-art img{
        height: inherit;
        right: 50%;
        transform: translateX(50%);
        top: inherit;
        position: relative;
        object-position: center center !important;
        margin: auto;
        display: block;
        margin-right: inherit;
    }
    .box-content{
        text-align: center;
        padding-right: inherit;
    }
    .box-content h1{
        font-size: 9vw !important;
        line-height: 1.1;
        letter-spacing: 1px;
        text-align: center;
        margin-top: 2vw;
        margin-bottom: 1.7vw;
    }
    .box-content p{
        margin-top: 0.7vw;
        font-size: 5vw;
        margin-bottom: 1vw;
        padding-right:inherit;
    }
    .logo-box{
        flex-direction: column;
        margin-left: inherit;
    }
    .logo-box img{
        width:35vw;
    }
    .logo-box p{
        font-size: 5vw;
        margin: 0 !important;
    }
}
.about-popup{
    position: fixed;
    z-index: 20;
    width: 100%;
    height: 100vh;
    background-image: url(../images/bg-gallery.jpg);
    background-position: center;
    background-size: cover;
    opacity: 0;
    z-index: -1;
    transition: .4s all;
}
.about-popup.active{
    opacity: 1;
    z-index: 10;
}

.about-content{
    background: white;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 80%;
    left: 50%;
    position: fixed;
    opacity: 0;
    transition: .4s all;
}
.about-popup.active .about-content{
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
.pop-upBox-about{
    display: grid;
    grid-template-columns:29% 49% 22%;
    width: 100%;
    padding: 0;
    justify-content: center;
    align-items: flex-start !important;
    padding-bottom: 2vw;
}
.image-about-box{
    width: 100%;
    height: 35vw;
    position: relative;
}
.image-about-box img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;left: 0;
    object-fit: cover;
    object-position: center;
}
.social-about{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.4vw;
    margin-top: 2vw;
}
.social-about img{
    max-width: 1.2vw;
    cursor: pointer;
}
.social-about img:nth-child(2){
    max-width: 2vw;
}
.box-content-about{
    padding-right: 3vw;
    margin-left: 5.5vw;
    padding-top: 1.5vw !important;
    text-align: start;
}
.box-content-about p{
    font-size: 0.94vw;
    padding-right: 3vw !important;
    font-weight: 300 ;
    margin-bottom: 0.7vw;
    color: #686868;
}
.heading-about-content img{
    position: relative;
    width: 23vw;
    height: 8vw;
    margin-bottom: 0;
}
.logo-box-about{
    margin-top: 8.5vw;
    display: block;
    text-align: start;
}
.logo-box-about h1{
    font-size: 1.3vw !important;
    text-transform: capitalize !important;
}
.logo-box-about p{
    font-size: 0.9vw;
    font-style: italic;
    font-weight: 600;
    margin-top: 1.4vw;
}
.close-about{
    position: absolute;
    right: -5%;
    top: 0;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}
.close-about img{
    max-width: 70%;
}
@media (max-width:768px){
    .about-content{
        width: 90%;
    }
    .pop-upBox-about{
        grid-template-columns: 100%;
    }
    .image-about-box{
        height:65vw;
    }
    .social-about{
        gap: 4vw;
        margin-top: 0;
        padding: 3vw 0;
    }
    .social-about img{
        max-width: 3.8vw !important;
        cursor: pointer;
    }
    .social-about img:nth-child(2){
        max-width: 7vw !important;
    }
    .box-content-about{
        padding-right: inherit;
        margin-left: inherit;
        padding: 0 6vw;
        width: 100%;
        height: 62vw;
        overflow-y: scroll;
    }
    .box-content-about p{
        font-size: 3.5vw;
        padding-right: inherit !important;
        margin-bottom: 0.7vw;
    }
    .logo-box-about{
        display: flex;
        flex-direction: column;
        margin-top: 2vw;
    }
    .logo-box-about h1{
        font-size: 5vw !important;
        margin-left: 5.7vw;
    }
    .close-about{
        right: -2%;
        top: -7%;
    }
}


.contact-popup{
    position: fixed;
    z-index: 20;
    width: 100%;
    height: 100vh;
    background-image: url(../images/bg-gallery.jpg);
    background-position: center;
    background-size: cover;
    opacity: 0;
    z-index: -1;
    transition: .4s all;
}
.contact-popup.active{
    opacity: 1;
    z-index: 10;
}

.contact-content{
    background: white;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 80%;
    left: 50%;
    position: fixed;
    opacity: 0;
    transition: .4s all;
    padding: 3.7vw 4vw;
}
.contact-popup.active .contact-content{
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
.form-input{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}
.form-input input{
    border: .14vw solid black;
    padding: .4vw .8vw;
    outline: none;
    background: transparent;
    font-size: 1.1vw;
    font-style: normal;
}
.form-input input::placeholder{
    color: #686868;
    font-style: italic;
}
.form-input textarea{
    border: .14vw solid black;
    padding: .4vw .8vw;
    outline: none;
    background: transparent;
    font-size: 1.1vw;
    font-style: normal;
}
.form-input textarea::placeholder{
    color: #686868;
    font-style: italic;
}
.form-input button{
    background: black;
    color: white;
    padding: .7vw;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-size: 1.2vw;
}
.pop-upBox-contact h1{
    margin-bottom: 1vw;
    font-size: 3.4vw;
}
.loader {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: #000;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
.salah{
    color: rgb(202, 2, 2);
}
.benar{
    color: rgb(0, 175, 0);
}
.loadStyle{
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
}
.sidebar-hidden{
    display: none !important;
}
@media only screen and (min-width: 1280px){
    .image-gallery{
        max-width: 46vw;
        max-height: 41vw;
        border: 1vw solid rgb(255, 255, 255) !important;
    }
    .info-button{
        top: 40vh;
        right: 23vw;
    }
    .swiper-slide-active .glow-effect{
        top: 40vh;
        right: 23vw;
    }
}
@media(max-width:768px){
    .contact-content{
        width: 90%;
        padding: 3.7vw 4vw;
    }
    .pop-upBox-contact h1{
        margin-bottom: 1.4vw;
        font-size: 6vw;
    }
    .form-input{
        gap:3vw;
    }
    .form-input input{
        border: .2vw solid black;
        padding: 1.3vw 3vw;
        font-size: 4vw;
    }
    .form-input textarea{
        border: .2vw solid black;
        padding: 1.3vw 3vw;
        font-size: 4vw;
    }
    .form-input button{
        padding: 1.5vw;
        font-size: 4vw;
    }
    .contact-content .close-about{
        top: -17%;
    }
}